body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

.logo {
    width: 190px;
    height: 37px;
    float: left;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-right: 10px;
}

.floatRight {
    float: right !important;
}

.bold {
    font-weight: bold;
}

.logout {
    width: 120px;
    height: 31px;
    background: #333;
    border-radius: 6px;
    margin: 16px 24px 16px 0;
    float: left;
}

.apb {
    color: #c2cd23 !important;
}

.login-header {
    padding-top: 100px;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
}

.login-form {
    max-width: 300px;
    margin: auto !important;
    padding: 24px 0;
}

.login-form-forgot {
    float: right;
}

.login-form-button {
    width: 100%;
}

.full-height {
    height: 100% !important;
}

html, body {
    height: 100% !important;
    margin: 0;
    padding: 0;
}

.Admin {
    background-color: #c2cd23 !important;
}

.User {

}

.selectTree {
    overflow-y: auto;
}

#components-layout-demo-top-side-2 .logo {
    width: 120px;
    height: 31px;
    background: #333;
    border-radius: 6px;
    margin: 16px 28px 16px 0;
    float: left;
}

.steps-content {
    margin-top: 8px;
    border: 1px dashed #e9e9e9;
    border-radius: 6px;

    min-height: 250px;
    text-align: center;
}

.steps-action {
    margin-top: 24px;
    text-align: center;
}

.ant-tabs-tab {
    margin-right: 15px !important;
}
#IE_message_container{
    width: 130px;
    height: 120px;
    position: relative;

}
#IE_message{
    position: absolute;
    top: 30vw;
}
.hidden{
    display: none;
}

@media (max-width: 1280px) {
    .ant-tabs-tab-disabled {
        margin: 0 !important;
        display: none !important;
        width: 0 !important;
    }

    .ant-tabs-tab {
        margin-right: 5px !important;
    }

}

@media (max-width: 1000px) {
    .ant-tabs-tab {
        margin-right: 0 !important;
    }
}

@media (max-height: 1000px) {
    .selectTree {
        max-height: 550px;
    }
}

@media (max-height: 800px) {
    .selectTree {
        max-height: 400px;
    }
}

@media (max-height: 600px) {
    .selectTree {
        max-height: 300px;
    }
}

.ant-tabs-tab-disabled {
    width: 0 !important;
    margin-right: 0 !important;
    padding-right: 0 !important;
}

.vertical-center-modal {
    text-align: center;
    white-space: nowrap;
}

.vertical-center-modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    width: 0;
}

.vertical-center-modal .ant-modal {
    display: inline-block;
    vertical-align: middle;
    top: 0;
    text-align: left;
}

.logo-menu {
    background-color: transparent !important;
}

.search-bar {
    height: 46px !important;
    font-size: 15px !important;
}

.ant-table-thead tr th span {
    font-weight: bold;
}

.ant-tree-treenode-disabled .ant-tree-switcher-noop {
    display: none !important;
}

.ant-tree-treenode-disabled .ant-tree-node-content-wrapper {
    padding-left: 24px !important;
}

.title-field-input {
    margin-left: 5px !important;
    padding-left: 5px !important;
    width: 70% !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}

.title-field {
    display: block;
    width: 70% !important;
}

.title-field:hover {
    cursor: pointer;
}

.centered-item {
    text-align: center;
    cursor: default;
}

.certain-category-search.ant-select-auto-complete .ant-input-affix-wrapper .ant-input-suffix {
    right: 12px;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
    color: #666;
    font-weight: bold;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group {
    border-bottom: 1px solid #F6F6F6;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item {
    padding-left: 16px;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
    text-align: center;
    cursor: default;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-disabled {
    cursor: default !important;
}

.settings-card .ant-card-body {
    padding: 0 !important;
}

.count-button {
    top: -1px;
}

.count-radio {
    top: 1px
}
.selection-button-align{
    margin-top: 3px;
}
.ant-menu-vertical.ant-menu-sub{
    position: absolute;
    bottom: -85px;

}
.ant-avatar.ant-avatar-lg.ant-avatar-circle{
    margin-right: 3px;
}
.ant-collapse-item.ant-collapse-item-active{
    border-bottom: none!important;
}
.ant-table-header{
    overflow: hidden!important;
    height: 72px;
}

